<div class="ama-modal"
     *ngIf="show"
     [id]="newId"
     [ngClass]>
  <div class="ama-modal__overlay"
       (click)="ignoreOverlayClick ? '': onClose()"></div>
  <div class="ama-modal__content">
    <div class="ama-modal__close">
      <ama-icon color="#8b898c"
                size="14px"
                name="close"
                (click)="onClose()"></ama-icon>
    </div>
    <ng-content></ng-content>
  </div>
</div>
